import { render, staticRenderFns } from "./Books.vue?vue&type=template&id=6f18eb55&"
import script from "./Books.vue?vue&type=script&lang=js&"
export * from "./Books.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports