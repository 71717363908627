<template>
  <div>
    <data-iterator :headers="payloadHeaders" :payload="payload" :key="reInit"> </data-iterator>
    <v-dialog v-model="drawer" absolute bottom temporary right width="700px">
      <v-card elevation="0" height="100%">
         <v-card-title class="primary white--text px-4">
          <span class="title">{{ modelObj._id ? 'Update Books' : 'Add Books'}} </span>
        </v-card-title>
        <v-form ref="form">
          <v-card-text>
            <v-row class="mx-4">
              <v-col cols="6">
                <v-text-field outlined dense hide-details label="Name" v-model="modelObj.name" :rules="setValidation('requiredValidation')"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined dense hide-details label="Code" v-model="modelObj.code"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea outlined dense hide-details label="About" v-model="modelObj.about"></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field outlined dense hide-details label="Author" v-model="modelObj.author"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined dense hide-details label="Price" v-model="modelObj.price"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field outlined dense hide-details label="Quantity" v-model="modelObj.qty"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox outlined dense hide-details label="For Academic" v-model="modelObj.is_academic"></v-checkbox>
              </v-col>
              <v-col cols="12">
                <v-select v-if="modelObj.is_academic" outlined dense hide-details label="For Class" v-model="modelObj.class" :items="listOfClasses" multiple item-text="name" item-value="_id" :rules="setValidation('requiredValidation')" small-chips></v-select>
                <v-text-field v-else outlined dense hide-details label="Row Number" v-model="modelObj.row"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-divider></v-divider>
        <v-card-actions>
          <v-layout class="d-flex justify-center">
            <v-btn color="success" class="mx-2" @click="saveUpdateHandler()">{{modelObj._id ? 'Update' : 'Save'}}</v-btn>
            <v-btn @click="drawer= false" color="error">Cancel</v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      reInit: 0,
      drawer: false,
      listOfClasses: [],
      modelObj: {},
      payload: {
        showBackBtn: () => this.$router.go(-1),
        create: () => this.drawer = true,
        items: [],
        showSelect: false,
        actionsList: [
          {
            click: (item) => this.editHandler(item._id),
            icon: 'mdi-pencil',
            color: 'primary'
          },
          {
            click: (item) => this.deleteHandler(item._id),
            icon: 'mdi-delete',
            color: 'error'
          }
        ]
      }
    }
  },
  components: {
    'data-iterator': () => import('@/components/DataIterator.vue')
  },
  watch: {
    drawer(val) {
      if (!val) {
        this.modelObj = {}
      }
    }
  },
  mounted () {
    this.getListHandler()
    this.getListOfClasses()
  },
  computed: {
    payloadHeaders () {
      return [{
        text: 'Code',
        align: 'start',
        sortable: false,
        value: 'code'
      }, {
        text: 'Name',
        value: 'name'
      }, {
        text: 'Author',
        value: 'author'
      }, {
        text: 'Is Academic',
        value: 'is_academic',
        type: 'BOOLEAN'
      }, {
        text: 'Row',
        value: 'row'
      }, {
        text: 'Class Room',
        value: 'class',
        isCustom: true
      }, {
        text: 'Actions',
        value: 'actions'
      }]
    }
  },
  methods: {
    getListOfClasses () {
      this.$_execute('get', 'classes').then(response => {
        this.listOfClasses = response.data
      })
    },
    getListHandler () {
      this.$_execute('get', 'books').then(response => {
        this.payload.items = response.data
        this.reInit++
      })
    },
    saveUpdateHandler () {
      let url = this.modelObj._id ? `books/${this.modelObj._id}` : `/books`
      let method = this.modelObj._id ? `put` : `post`
      this.$_execute(method, url, this.modelObj).then(() => {
        this.$root.$emit('snackbar', { message: this.modelObj._id ? 'Updated Successfully' : 'Saved Successfully', color: 'success'})
        this.$refs.form.reset()
        this.modelObj = {}
        this.drawer = false
        this.getListHandler()
      })
    },
    editHandler (id) {
      this.$_execute('get', `books/${id}`).then(response => {
        this.drawer = true
        this.modelObj = response.data[0]
      })
    },
    deleteHandler (id) {
      this.$root.$emit('delete', {
        module: 'books',
        _id: id,
        onResolve: () => {
          this.dialog = false
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.getListHandler()
        }
      })
    }
  }
}
</script>
